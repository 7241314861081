<script>

  import Icons from "@/components/reuseables/Icons.vue";
  import {
    mapGetters
  } from 'vuex';
  export default {
    name: 'ViewUnits',
    components: {
      Icons
    },
    computed: {
      ...mapGetters('paddler', ['selectedUnit'])
    },
  }

</script>

<template>
  <div class="tw-flex tw-justify-center">

    <v-btn class="btn" @click="$router.push({name:'AllUnits'})">
      <icons name="back" width="16px" class="mr-2" />
      <p class="btn-text tw-normal-case">View Units</p>
    </v-btn>
    <div class="main-body mt-14 ">
        <div class="tw-flex">
          <v-col class="lighter-text" cols="6" sm="4">Unit Name</v-col>
          <v-col class="darker-text" cols="6" sm="8">{{ selectedUnit.name || 'N/A' }}</v-col>
        </div>

        <div class="tw-flex">
          <v-col class="lighter-text" cols="6" sm="4">Unit Head</v-col>
          <v-col class="darker-text" cols="6" sm="8">{{ selectedUnit.unitHead || 'N/A' }}</v-col>
        </div>
        <div class="tw-flex">
          <v-col class="lighter-text" cols="6" sm="4">Departments</v-col>
            <v-col cols="8" sm="8">
              <p class="darker-text tw-py-2" v-for="item in selectedUnit.departments" :key="item.id">- {{ item.name}}</p>
            </v-col>
        </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.main-body{
  width: 609px;
  height: fit-content;
  background: #FEFFFF;
  border-radius: 10px;
  padding: 35px 35px 30px;
  @media screen and (max-width: 375px){
    width: 355px;
    margin-left: -50px;
  }
}
.btn{
  margin-right: -145px;

}
.btn-txt{
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: #263238;
}
.text-headings {
  color: #4f4f4f;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.lighter-text{
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #939393;
  margin-left: 60px;
  @media screen and (max-width: 375px){
    margin: 0;
  }
}
.darker-txt{
  color: #263238;
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: -50px;
  margin-top: -10px;
  @media screen and (max-width: 375px){
    margin-left: 0;
    font-size: 14px;
  }
}

  .main-body {
    width: 609px;
    height: fit-content;
    background: #FEFFFF;
    border-radius: 10px;
    padding: 20px;
  }

  .btn {
    margin-right: -145px;
  }

  .btn-text {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: #263238;
  }

  .text-headings {
    color: #4f4f4f;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .lighter-text {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    color: #939393;
  }

  .darker-text {
    color: #263238;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
  }
</style>